<template>
  <transition appear>
    <div class="mt-10 w-12/13 m-auto">
      <h1 class="text-grey w-11/12 m-auto my-4 font-bold text-3xl">
        Locum Hire Dashboard
      </h1>
      <div
        class="
          flex flex-wrap
          md:flex-nowrap
          items-center
          w-11/12
          m-auto
          min-h-100
          gap-y-5
          md:gap-y-0
          first-half
        "
      >
        <div
          class="
            flex flex-row flex-wrap
            w-full
            sm:w-3/5
            box-border
            md:gap-6
            menu-container
          "
        >
          <base-tile
            icon="person_add"
            title="My Staff"
            iconSize="5xl"
            :link="'my-staff'"
            @click="showCreateUser = true"
          />
          <base-tile
            icon="currency_pound"
            title="Billing"
            iconSize="5xl"
            :link="'locum-billing'"
            @click="showCreateUser = true"
          />
          <base-tile
            icon="pending_actions"
            title="Sessions"
            iconSize="5xl"
            :link="'locum-sessions'"
            @click="showCreateUser = true"
          />
        </div>
      </div>
      <div
        class="
          flex flex-wrap
          lg:flex-nowrap
          justify-center
          md:justify-start
          w-11/12
          m-auto
          my-5
          second-half
        "
      >
        <!-- OPEN Vacancies TITLE -->
        <div
          v-if="!isLoading"
          class="
            bg-lightGrey
            rounded-xl
            w-full
            xl:w-3/5
            shadow-md
            calendar-container
          "
          :key="refreshCalendar"
        >
          <!-- <div class="pl-4 py-4">
            <h2 class="text-grey font-bold">March, 2022</h2>
          </div> -->
          <!-- <template v-if="!isLoading"> -->
          <div class="w-11.5/12 mx-auto mt-14 relative">
            <Calendar
              style="border: 2px solid grey; border-radius: 10px"
              :class="'custom-calendar rounded-xl overflow-x-scroll lg:overflow-visible'"
              :masks="masks"
              :attributes="attributes"
              disable-page-swipe
              is-expanded
              ref="calendar"
            >
              <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden relative">
                  <span
                    class="
                      day-label
                      text-sm text-gray-900
                      absolute
                      bottom-1
                      right-1
                    "
                    >{{ day.day }}</span
                  >
                  <span
                    @click="addDaySession(day)"
                    class="
                      material-icons
                      absolute
                      text-teal
                      border-2 border-teal
                      rounded-full
                      top-1
                      right-1
                      overflow-hidden
                      cursor-pointer
                      ml-1
                    "
                  >
                    add
                  </span>
                  <div
                    class="
                      flex-grow
                      overflow-y-auto overflow-x-auto
                      w-9/12
                      mt-1
                      mx-1
                    "
                  >
                    <p
                      v-for="(session, index) in attributes"
                      :key="session.id"
                      class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                      :class="[
                        {
                          ['bg-transparent text-teal border-2 border-teal']:
                            (index + 1) % 2 === 0,
                        },
                        {
                          ['bg-teal text-white']: (index + 1) % 2 !== 0,
                        },
                        {
                          'w-11/12': index === 0,
                        },
                      ]"
                    >
                      {{ session.customData.title }}
                    </p>
                  </div>
                </div>
              </template>
            </Calendar>
            <div class="flex justify-between align-center w-full pt-1">
              <BaseButton
                classTailwind="flex justify-center align-center ml-0"
                @click="previousMonth"
              >
                <span class="material-icons"> chevron_left </span>
                <span class="m-auto"> Previous Month </span>
              </BaseButton>
              <BaseButton
                classTailwind="flex justify-center align-center mr-0"
                @click="nextMonth"
              >
                <span class="m-auto"> Next Month </span>
                <span class="material-icons flex align-center">
                  chevron_right
                </span>
              </BaseButton>
            </div>
          </div>
        </div>
        <div class="ml-5 w-full xl:w-2/5 sessions-container">
          <div class="bg-lightGrey rounded-xl w-full p-5 add-session">
            <h2 class="text-grey text-2xl font-bold">Add New Session</h2>
            Session Name:
            <input
              type="text"
              maxlength="50"
              placeholder="Please enter session name"
              v-model="createSessionPayload.name"
              class="
                focus:border-teal
                p-1
                border-2 border-transparent
                px-1
                mb-1
                mx-1
                inline-block
                cursor-pointer
                bg-lightGrey
                font-bold
                text-teal
                placeholder-teal
              "
              :class="{
                'border-red':
                  validate && v$.createSessionPayload.name.$errors.length,
              }"
              ref="sessionName"
            />
            <div class="my-1 leading-5">
              I need
              <select
                v-model="createSessionPayload.role"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.role.$errors.length,
                }"
              >
                <option :value="null">Role</option>
                <option v-for="role in roles" :key="role.code" :value="role.code">
                  {{ role.label }}
                </option>
              </select>
              from
              <input
                type="date"
                ref="start_date"
                v-model="createSessionPayload.start_date"
                placeholder="Start Date"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate &&
                    v$.createSessionPayload.start_date.$errors.length,
                }"
              />
              at
              <input
                type="time"
                v-model="createSessionPayload.start_time"
                placeholder="Start Time"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate &&
                    v$.createSessionPayload.start_time.$errors.length,
                }"
              />
              until
              <input
                type="date"
                placeholder="End Date"
                v-model="createSessionPayload.end_date"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.end_date.$errors.length,
                }"
              />
              at
              <input
                type="time"
                v-model="createSessionPayload.end_time"
                placeholder="Start Date"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.end_time.$errors.length,
                }"
              />
            </div>
            <div class="my-0">
              The hiring rate is
              <i class="bg-lightGrey font-bold text-teal">£</i>
              <input
                type="number"
                min="0"
                placeholder="Wage"
                v-model="createSessionPayload.rate"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  w-24
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                  placeholder-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.rate.$errors.length,
                }"
              />
              per
              <select
                v-model="createSessionPayload.unit"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.unit.$errors.length,
                }"
              >
                <option :value="null">Time Unit</option>
                <option value="hourly">
                  Hour
                </option>
                <option v-if="createSessionPayload.role == 29" value="session">
                  Session
                </option>
              </select>
            </div>
            <div class="my-0">
              The position is located at
              <select
                v-model="createSessionPayload.location"
                class="
                  focus:border-teal
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  bg-lightGrey
                  font-bold
                  text-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.location.$errors.length,
                }"
              >
                <option :value="null">Location</option>
                <option
                  v-for="location in locations"
                  :key="location.id"
                  :value="
                    location.practice_name[0].toUpperCase() +
                    location.practice_name.slice(1)
                  "
                >
                  {{
                    location.practice_name[0].toUpperCase() +
                    location.practice_name.slice(1)
                  }}
                </option>
              </select>
            </div>
            <div
              v-if="
                validate === true &&
                (v$.createSessionPayload.role.$errors.length ||
                  v$.createSessionPayload.start_date.$errors.length ||
                  v$.createSessionPayload.end_date.$errors.length ||
                  v$.createSessionPayload.start_time.$errors.length ||
                  v$.createSessionPayload.end_time.$errors.length ||
                  v$.createSessionPayload.rate.$errors.length ||
                  v$.createSessionPayload.unit.$errors.length ||
                  v$.createSessionPayload.location.$errors.length)
              "
            >
              <div class="input-errors border-2 border-red py-2 px-4">
                <div class="error-msg text-red">Missing input field.</div>
              </div>
            </div>
            <div class="flex justify-end">
              <BaseButton @click.prevent="createSession"> Confirm </BaseButton>
            </div>
          </div>
          <div
            class="bg-lightGrey rounded-xl min-h-cardM p-5 mt-5 todays-sessions"
          >
            <h2 class="text-grey text-2xl font-bold">Today's Sessions</h2>
            <div class="mt-5">
              <div class="flex text-sm gap-x-1 mb-1">
                <span
                  @click="allSessionsFunc"
                  class="
                    tab
                    cursor-pointer
                    inline-block
                    py-1
                    px-7
                    rounded-md
                    border-2 border-teal
                  "
                  :class="{ buttonActive: tableState === 'all' }"
                >
                  All
                </span>
                <span
                  @click="vacantSessionsFunc"
                  class="
                    tab
                    cursor-pointer
                    inline-block
                    py-1
                    px-7
                    rounded-md
                    border-2 border-teal
                  "
                  :class="{ buttonActive: tableState === 'vacant' }"
                >
                  Vacant
                </span>
                <span
                  @click="filledSessionsFunc"
                  class="
                    tab
                    cursor-pointer
                    inline-block
                    py-1
                    px-7
                    rounded-md
                    border-2 border-teal
                  "
                  :class="{ buttonActive: tableState === 'filled' }"
                >
                  Filled
                </span>
              </div>
              <div
                class="
                  border-2
                  overflow-y-scroll
                  border-grey
                  w-full
                  h-64
                  bg-white
                "
              >
                <div
                  v-if="todaysSessions?.length === 0"
                  class="
                    pt-20
                    mb-20
                    flex flex-col
                    overflow-y-scroll
                    justify-center
                    items-center
                    h-64
                  "
                >
                  <span
                    class="material-icons flex align-center text-8xl text-teal"
                  >
                    event_busy
                  </span>
                </div>
                <va-accordion
                  v-for="session in todaysSessions"
                  :key="session.id"
                  class="flex justify-between align-center w-full"
                >
                  <va-collapse
                    :key="session.id"
                    :header="session.name"
                    class="w-full border-b-4 border-white"
                  >
                    <div
                      class="
                        session-card
                        w-11.5/12
                        border-2
                        flex
                        border-grey
                        rounded-xl
                        mx-auto
                        my-2
                        py-4
                        px-4
                      "
                    >
                      <!-- <div class="position rounded-xl bg-lightGrey w-2/4">

                  </div> -->
                      <div class="flex flex-col w-2/4">
                        <div class="font-bold flex session-row">
                          <div>
                            <span class="text-grey"> {{ session.name }} </span>
                          </div>
                        </div>
                        <div class="mt-2 flex">
                          <span class="text-grey font-bold">
                            {{ session.location }}
                          </span>
                        </div>
                        <div class="flex session-row mt-3">
                          <div class="flex gap-x-2">
                            <span class="text-teal font-bold">
                              Start Date:
                            </span>
                            <span class="text-grey ml-3">
                              {{ session.start_date }}
                            </span>
                          </div>
                        </div>
                        <div class="flex session-row">
                          <div class="flex gap-x-2">
                            <span class="text-teal font-bold">
                              Start Time:
                            </span>
                            <span class="text-grey ml-3">
                              {{ session.start_time }}
                            </span>
                          </div>
                        </div>
                        <div class="flex session-row mt-3">
                          <div class="flex gap-x-2">
                            <span class="text-teal font-bold">
                              Finish Date:
                            </span>
                            <span class="text-grey ml-2">
                              {{ session.end_date }}
                            </span>
                          </div>
                        </div>
                        <div class="flex session-row">
                          <div class="flex gap-x-2">
                            <span class="text-teal font-bold">
                              Finish Time:
                            </span>
                            <span class="text-grey ml-2">
                              {{ session.end_time }}
                            </span>
                          </div>
                        </div>
                        <div class="flex session-row">
                          <div class="flex session-row mt-3">
                            <div class="rate flex justify-center">
                              <span class="font-bold text-teal"> Rate: </span>
                              <span class="text-grey ml-6">
                                £{{ session.rate }}/{{ session.unit }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="tableState == 'filled'" class="w-2/4">
                        <h3 class="text-grey font-semibold text-center">
                          Filled By:
                        </h3>
                        <!-- <div class="position flex items-center justify-center">
                      <span class="material-icons text-8xl text-teal my-4">
                        person_add
                      </span>
                    </div> -->
                        <!-- Profile card for daily profile -->
                        <div
                          class="
                            mt-2
                            profile-card
                            rounded-xl
                            py-3
                            px-2
                            relative
                            text-grey
                            bg-lightGrey
                            flex flex-row
                            items-center
                            justify-start
                          "
                        >
                          <div
                            class="
                              overflow-x-hidden
                              rounded-full
                              w-24
                              h-24
                              border-2 border-teal
                              p-2
                              flex
                              justify-center
                              items-center
                            "
                          >
                            <span
                              class="
                                material-icons
                                w-full
                                h-full
                                flex
                                items-center
                                pl-2
                                text-white text-6xl
                                rounded-full
                                bg-orange
                              "
                            >
                              person_filled
                            </span>
                          </div>
                          <div class="ml-2 text-sm">
                            <h3 class="font-bold mt-2">
                              {{ session.locums[0]?.profile?.first_name }}&nbsp;
                              {{ session.locums[0]?.profile?.last_name }}
                            </h3>
                            <p>
                              <span class="font-bold">{{
                                session.locums[0]?.profile?.primary_role
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </va-collapse>
                </va-accordion>

                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import baseTile from "@/components/ui/baseComponents/baseTile.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

//import moment from "moment";

import { Calendar } from "v-calendar";
// import DatePicker from "v-calendar/lib/components/date-picker.umd";
import "v-calendar/dist/style.css";
import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import Swal from "sweetalert2";

export default {
  components: {
    baseTile,
    Calendar,
    BaseButton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  async created() {
    await this.fetchMonthlySessions();
    await this.fetchTodaysSessions();
    await this.fetchRoles();
    await this.fetchLocations();
    this.getMonthlySessions();
    this.getTodaysSessions();
    this.getRoles();
    this.getLocations();
  },
  data() {
    return {
      year: "",
      month: "",
      validate: true,
      monthlySessions: [],
      todaysSessions: [],
      filledSessions: [],
      vacantSessions: [],
      allSessions: [],
      createSessionPayload: {
        practice: 1,
        role: null,
        name: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        rate: null,
        unit: null,
        location: null,
      },
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      roles: [],
      locations: [],
      refreshCalendar: 0,
      tableState: "all",
    };
  },
  validations() {
    return {
      createSessionPayload: {
        name: {
          required: helpers.withMessage("Name field is required", required),
        },
        role: {
          required: helpers.withMessage("Role field is required", required),
        },
        start_date: {
          required: helpers.withMessage(
            "Start-date field is required",
            required
          ),
          minValue: helpers.withMessage("Required (Date should not be less than today.)", value => value >= new Date((new Date()).valueOf() - 1000*60*60*24).toISOString()),
        },
        end_date: {
          required: helpers.withMessage("End-date field is required", required),
          minValue: helpers.withMessage("Required (Date should not be less than today.)", value => value >= new Date((new Date()).valueOf() - 1000*60*60*24).toISOString()),
        },
        start_time: {
          required: helpers.withMessage(
            "Start-time field is required",
            required
          ),
        },
        end_time: {
          required: helpers.withMessage("End-time field is required", required),
        },
        rate: {
          required: helpers.withMessage("Wage field is required", required),
        },
        unit: {
          required: helpers.withMessage("Unit field is required", required),
        },
        location: {
          required: helpers.withMessage("Location field is required", required),
        },
      },
    };
  },
  methods: {
    async fetchRoles() {
      try {
        await this.$store.dispatch("ReLocums/fetchRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getRoles() {
      this.roles = this.$store.getters["ReLocums/getRoles"];
      return this.$store.getters["ReLocums/getRoles"];
    },
    getLocations() {
      this.locations = this.$store.getters["ReLocums/getLocations"];
      return this.locations;
    },
    addDaySession(dateObject) {
      this.createSessionPayload.start_date = dateObject.id;
      this.$refs.sessionName.focus();
    },
    async fetchMonthlySessions() {
      try {
        if (!this.year || !this.month) {
          const date = new Date().toISOString().split("-");
          this.year = date[0];
          this.month = date[1];
        }
        await this.$store.dispatch("ReLocums/fetchMonthlySessions", {
          date: `${this.year}-${this.month}`,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchTodaysSessions() {
      try {
        /**
         *
         * Correct the Date Format in the Payload
         *
         */
        const date = new Date().toISOString().split("T")[0];
        await this.$store.dispatch("ReLocums/fetchTodaysSessions", {
          date: `${date}`,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getTodaysSessions() {
      this.todaysSessions = this.$store.getters["ReLocums/getTodaysSessions"];
      this.allSessions = this.todaysSessions;
      this.filledSessions = this.todaysSessions.filter(
        (session) => session.locums_count > 0
      );
      this.vacantSessions = this.todaysSessions.filter(
        (session) => session.locums_count < 1
      );
      return this.todaysSessions;
    },
    getMonthlySessions() {
      this.monthlySessions = [
        ...this.$store.getters["ReLocums/getMonthlySessions"],
      ];

      this.monthlySessions = this.monthlySessions.map((session, index) => {
        let newObj = {};
        newObj.key = index + 1;
        newObj.customData = { title: session.name };
        newObj.dates = new Date(session.start_date);
        return newObj;
      });
      this.attributes = [...this.monthlySessions];
      return this.$store.getters["ReLocums/getMonthlySessions"];
    },
    async createSession() {
      this.validate = true;
      const validated = await this.v$.$validate();
      if (validated === true) {
        await this.$store.dispatch(
          "ReLocums/createSession",
          this.createSessionPayload
        );
        Swal.fire({
          title: "Done",
          text: "Session created successfully!",
          icon: "success",
        });
        if (
          this.createSessionPayload.start_date ===
          new Date().toISOString().split("T")[0]
        ) {
          await this.fetchTodaysSessions();
          this.getTodaysSessions();
        }
        await this.fetchMonthlySessions();
        this.getMonthlySessions();
        this.refreshCalendar++;
        this.createSessionPayload = {
          practice: 1,
          role: null,
          name: null,
          start_date: null,
          end_date: null,
          start_time: null,
          end_time: null,
          rate: null,
          unit: null,
          location: null,
        };
        this.validate = false;
      }
    },
    async nextMonth() {
      const calendar = this.$refs.calendar;
      if (Number(this.month) + 1 == 13) {
        this.month = 1;
        this.year = Number(this.year) + 1;
      } else {
        this.month = Number(this.month) + 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlySessions();
      this.getMonthlySessions();
      await calendar.move(1);
    },
    async previousMonth() {
      const calendar = this.$refs.calendar;
      if (Number(this.month) - 1 == 0) {
        this.month = 12;
        this.year = Number(this.year) - 1;
      } else {
        this.month = Number(this.month) - 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlySessions();
      this.getMonthlySessions();
      await calendar.move(-1);
    },

    allSessionsFunc() {
      this.tableState = "all";
      this.todaysSessions = this.allSessions;
    },
    vacantSessionsFunc() {
      this.tableState = "vacant";
      this.todaysSessions = this.vacantSessions;
    },
    filledSessionsFunc() {
      this.tableState = "filled";
      this.todaysSessions = this.filledSessions;
    },
  },
};
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>

<style>
.buttonActive {
  border-color: teal;
  background-color: teal;
  color: white;
  padding-top: 2px;
}
.vc-header {
  padding: 0;
}
.vc-title {
  color: grey;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -45px;
  font-weight: bold;

  font-size: 1.5rem;
  line-height: 2rem;
  z-index: 9999;
}

.vc-arrows-container {
  display: none;
}

.vc-weekday {
  text-transform: uppercase;
  color: grey;
  border-bottom: 2px solid silver;
  border-left: 1px solid silver;
  padding: 15px;
}
.vc-weeks > .vc-weekday:first-of-type {
  border-left: none;
}
.vc-weeks {
  padding: 0;
}
.vc-day {
  min-height: 5.5rem;
  max-height: 5.5rem;
  min-width: 7.2rem;
  border-bottom: 1px solid silver;
  border-left: 1px solid silver;
}
.vc-weeks > .on-left {
  border-left: none;
}
.vc-weeks > .on-bottom {
  border-bottom: none;
}
.max-h-cardM {
  max-height: 227px;
}
.step-connector {
  margin-left: 85px;
}
.min-w-16 {
  min-width: 4rem;
}
select,
input {
  outline: none;
}

@media screen and (max-width: 1809px) {
  .second-half {
    flex-direction: column;
  }

  .calendar-container {
    min-width: 100%;
  }
  .sessions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    min-width: 100%;
    margin-left: 0 !important;
  }
  .add-session,
  .todays-sessions {
    min-width: 49.5%;
    max-width: 49.5%;
    margin-left: 0;
    margin-top: 0 !important;
  }

  .first-half {
    flex-direction: row;
    flex-wrap: nowrap !important;
  }
  .menu-container {
    min-width: 25%;
  }
  .notifications-container {
  }
  @media screen and (max-width: 1499px) {
    .menu-container {
      min-width: 30%;
    }
  }
  @media screen and (max-width: 1289px) {
    .menu-container {
      min-width: 40%;
    }
  }
  @media screen and (max-width: 1289px) {
    .menu-container {
      min-width: 100%;
      margin-bottom: 15px;
      justify-content: center;
      align-items: center;
    }
    .notifications-container,
    .add-session,
    .todays-sessions {
      min-width: 100%;
    }
    .first-half,
    .sessions-container {
      flex-direction: column;
    }
    .todays-sessions {
      margin-top: 15px !important;
    }
  }
}
</style>
